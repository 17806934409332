<template>
  <v-container fluid style="padding-top: 0px">
    <v-card class="mb-2">
      <v-card-actions>
        <v-badge bottom v-model="hasGuestFilter" color="red" overlap class="mr-4">
          <template v-slot:badge>{{filters.guests.length}}</template>
          <v-btn @click="toggleGuestFilters=!toggleGuestFilters; toggleProgramFilters=false">
            Filter by guest
            <v-icon size="15" class="mr-1" v-if="!toggleGuestFilters">mdi-chevron-double-down</v-icon>
            <v-icon size="15" class="mr-1" v-if="toggleGuestFilters">mdi-chevron-double-up</v-icon>
          </v-btn>
        </v-badge>
        <v-badge bottom v-model="hasProgramFilter" color="red" overlap class="mr-4">
          <template v-slot:badge>{{filters.programs.length}}</template>
          <v-btn @click="toggleProgramFilters=!toggleProgramFilters; toggleGuestFilters=false">
            Filter by support category
            <v-icon size="15" class="mr-1" v-if="!toggleProgramFilters">mdi-chevron-double-down</v-icon>
            <v-icon size="15" class="mr-1" v-if="toggleProgramFilters">mdi-chevron-double-up</v-icon>
          </v-btn>
        </v-badge>
        <v-btn class="mr-4" @click="clearFilters">
          <v-icon size="15" class="mr-1">mdi-filter-remove</v-icon>Clear Filters
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2"
          fab
          small
          @click="setRosterView('calendar')"
          :class="rosterView=='calendar'?'light-green lighten-4':''"
        >
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          small
          @click="setRosterView('list')"
          :class="rosterView=='list'?'light-green lighten-4':''"
        >
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
        <v-btn v-if= this.isAdmin @click="addEvent">
          <v-icon size="15" class="mr-1">mdi-plus</v-icon>Add event
        </v-btn>
      </v-card-actions>
      <v-expand-transition mode="in-out">
        <v-form ref="guestsForm">
          <div
            v-if="toggleGuestFilters"
            style="padding:20px; border-top: 1px solid #d8d8d8;    background-color: #f5f5f5"
          >
            <guest-picker :min-characters="4" v-on:guest-selected="addGuestFilter"></guest-picker>
            <v-list style="background-color:transparent">
              <v-list-item v-for="(guest,index) in filters.guests" :key="guest.id">
                <v-list-item-avatar>
                  <v-badge class="align-self-center" bottom overlap color="transparent">
                    <template v-slot:badge>
                      <v-icon color="blue" v-if="guest.type=='Participant'">mdi-account</v-icon>
                      <v-icon color="green " v-if="guest.type=='Staff'">mdi-account-tie</v-icon>
                      <v-icon v-if="guest.type=='Other'">mdi-account-question</v-icon>
                    </template>
                    <img :src="guest.avatar" v-if="guest.avatar" style="height:40px" />
                    <v-icon v-if="!guest.avatar || guest.avatar==null">mdi-account</v-icon>
                  </v-badge>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{guest.name?guest.name:guest.email}} ({{guest.type}})</v-list-item-title>
                  <v-list-item-subtitle v-if="guest.name">{{guest.email}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon @click="removeGuestFilter(index)">mdi-trash-can-outline</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-form>
      </v-expand-transition>
      <v-expand-transition mode="in-out">
        <v-form ref="programsForm">
          <div
            v-if="toggleProgramFilters"
            style="padding:20px;border-top: 1px solid #d8d8d8;    background-color: #f5f5f5"
          >
           <!--<v-select
              v-model="selectedProgram"
              :items="programs"
              @change="addProgramFilter"
              label="Support Category"
              data-vv-as="gender"
              item-text="item"
              return-object
              name="programs"
              clearable
              :error-messages="errors.collect('programs')"
            ></v-select>
            --> 
              <v-select
              item-text="name"
              v-model="supportCategory"
              :items="lineItemDefinition.supportCategories"
              label="Support Category"
              return-object
            ></v-select>
            <v-list style="background-color:transparent">
              <v-list-item v-for="(program,index) in filters.programs" :key="index">
                <v-list-item-content>
                  <v-list-item-title v-text="program.item"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon @click="removeProgramFilter(index)">mdi-trash-can-outline</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </v-form>
      </v-expand-transition>
    </v-card>
    <v-card class="roster-card" v-if="rosterView=='calendar'">
      <v-card-text v-if= this.isAdmin >
        <full-calendar
          :events="filteredEvents"
          ref="calendar"
          :editable="true"
          :defaultDate="currentDate"
          @event-selected="eventSelected"
          @datesRender="datesRender"
          @eventClick="eventSelected"
          @dateClick="addEvent"
          @eventResize="resizeEvent"
          @eventDrop="resizeEvent"
          :plugins="calendarPlugins"
          :views="views"
          :header="{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      }"
        ></full-calendar>
      </v-card-text>
      <v-card-text v-if= !this.isAdmin >
        <full-calendar
          :events="filteredEvents"
          ref="calendar"
          :editable="true"
          :defaultDate="currentDate"
          @event-selected="eventSelected"
          @datesRender="datesRender"
           @eventClick="eventSelected"
          @eventResize="resizeEvent"
          @eventDrop="resizeEvent"
          :plugins="calendarPlugins"
          :views="views"
          :header="{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      }"
        ></full-calendar>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="rosterView=='list'">
      <v-card-text style="display:grid">
        <v-data-table
          id="list-table"
          ref="datatable"
          :headers="[{text: 'Title'},{text: 'Program'},{text: 'Dates'},{text: 'Time'},{text: 'Colour'},{text: ''}]"
          :items="events"
          item-key="index"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.title}}</td>
              <td>{{ props.item.program.item}}</td>
              <td
                v-if=" props.item.isReccuring"
              >{{ props.item.startRecurString}} {{ props.item.startRecurString !==props.item.endRecurString? "- " + props.item.endRecurString:""}}</td>
              <td
                v-if="! props.item.isReccuring"
              >{{ props.item.startDateString}} {{ props.item.startDateString !==props.item.endDateString? "- " + props.item.endDateString:""}}</td>
              <td>{{ props.item.startTimeString }} - {{ props.item.endTimeString }}</td>
              <td>
                <v-icon :color=" props.item.backgroundColor">mdi-water</v-icon>
              </td>

              <td class="justify-center layout px-0">
                <v-icon
                  slot="activator"
                  small
                  class="mr-2"
                  @click="eventSelected({event:props.item})"
                >mdi-pencil</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);
import * as eventService from "../../services/eventService";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import * as refDataService from "../../services/referenceDataService";
import * as lineItemService from "../../services/lineItemSevice";
import moment from "moment";
import GuestPicker from "./guest-picker";
// must manually include stylesheets for each plugin
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "roster",
  components: {
    FullCalendar,
    GuestPicker
  },
  data: () => ({
    calendarPlugins: [
      // plugins must be defined in the JS
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin // needed for dateClick
    ],

    selectedProgram: "",
    programs: [],
    lineItemDefinition: {},
    toggleGuestFilters: false,
    toggleProgramFilters: false,
    date: "2019-05-25",
    isUpdating: false,
    name: "",
    datetime: new Date(),
    selectedDate: "2019-05-25",

    valid: true,
    startMenu: false,
    endMenu: false,
    isAdmin: false,
    form: {},

    views: {
      week: {
        columnHeaderFormat: {
          weekday: "short",
          month: "short",
          day: "numeric",
          omitCommas: true
        }
      }
    },

    events: [],
    filteredEvents: []
  }),
  mounted() {
    
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    user() {
      return this.$store.getters.user;
    },
    currentDate() {
      return this.$store.getters.currentDate;
    },
    rosterView() {
      return this.$store.getters.rosterView;
    },
    calendarView() {
      return this.$store.getters.calendarView;
    },
    filters() {
      return this.$store.getters.filters;
    },
    hasGuestFilter() {
      return this.$store.getters.filters.guests.length > 0;
    },
    hasProgramFilter() {
      return this.$store.getters.filters.programs.length > 0;
    }
  },
  methods: {
    init() {
      let me = this;
      this.selectedDate = new Date().toISOString();
      refDataService
        .listByKey(me.currentOrganisation.id, "programs")
        .then(results => {
          results.docs.map(doc => {
            let item = doc.data();
            item.id = doc.id;
            me.programs.push(item);
          });
        });
      //console.log(me.programs);
      this.lineItemDefinition = {};
      lineItemService
        .list()
        .then(result => {
          result.docs.map(doc => {
            const lid = doc.data();
            const dt = new Date();
            const from = new Date(lid.effectiveFromDate.replace(/-/g, "/"));
            const to = new Date(lid.effectiveToDate.replace(/-/g, "/"));
            if(dt>=from && dt<=to){
              this.lineItemDefinition = lid;
              this.lineItemDefinition.id = doc.id;
              console.log(this.lineItemDefinition.supportCategories);
            }
          });
        });
        

      if (me.rosterView == "list") {
        me.getEvents(
          this.currentDate,
          moment(this.currentDate)
            .add(30, "days")
            .toDate()
        );
      }
    },
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    applyFilters(me) {
      me.filteredEvents = me.events.filter(e => {
        if (
          me.filters.guests.length === 0 &&
          me.filters.programs.length === 0
        ) {
          return true;
        }

        let found = false;
        e.guests.forEach(element => {
          if (!found) {
            let match = me.filters.guests.find(guest => {
              return guest.type === "Other"
                ? element.email === guest.email
                : element.id === guest.id;
            });
            if (match !== undefined) {
              found = true;
            }
          }
        });

        if (!found && me.filters.programs.length > 0) {
          let match = me.filters.programs.find(x => {
            return e.program.id === x.id;
          });
          if (match !== undefined) {
            found = true;
          }
        }

        return found;
      });
      me.$forceUpdate();
    },
    setRosterView(type) {
      this.$store.commit("setRosterView", type);
    },
    clearFilters() {
      this.$refs.guestsForm.reset();
      this.$refs.programsForm.reset();
      this.filters.guests.splice(0, this.filters.guests.length);
      this.filters.programs.splice(0, this.filters.programs.length);
      this.$store.commit("setFilters", this.filters);
      this.applyFilters(this);
    },
    clearGuestFilterForm() {
      this.$refs.guestsForm.reset();
    },
    clearProgramFilterForm() {
      this.$refs.programsForm.reset();
    },
    addGuestFilter(guest) {
      if (this.filters.guests == undefined) {
        this.filters.guests = [];
      }
      const match = this.filters.guests.find(x => {
        return guest.type === "Other"
          ? x.email === guest.email
          : x.id === guest.id;
      });
      if (match == null) {
        this.filters.guests.push(guest);
      }
      this.$store.commit("setFilters", this.filters);
      this.clearGuestFilterForm();
      this.applyFilters(this);
    },
    removeGuestFilter(index) {
      this.filters.guests.splice(index, 1);
      this.$store.commit("setFilters", this.filters);
      this.applyFilters(this);
    },
    addProgramFilter(program) {
      if (this.filters.programs == undefined) {
        this.filters.programs = [];
      }
      const match = this.filters.programs.find(x => {
        return program.id === x.id;
      });
      if (match == null) {
        this.filters.programs.push(program);
      }
      this.clearProgramFilterForm();
      this.$store.commit("setFilters", this.filters);
      this.applyFilters(this);
    },
    removeProgramFilter(index) {
      this.filters.programs.splice(index, 1);
      this.$store.commit("setFilters", this.filters);
      this.applyFilters(this);
    },
    datesRender(info) {
      const me = this;
      const startOfTheMonth = info.view.activeStart;
      const endOfTheMonth = info.view.activeEnd;
      console.log(`start  ${startOfTheMonth}`);
      console.log(`end  ${endOfTheMonth}`);
      me.$store.commit("setCurrentDate", info.view.currentStart);
      me.$store.commit("setCalendarView", info.view.viewSpec.type);
      me.getEvents(startOfTheMonth, endOfTheMonth);
    },
    getEvents(startOfTheMonth, endOfTheMonth) {
      const me = this;
    
    if (
      this.user.roles.indexOf("System Administrator") > -1 ||
      this.user.roles.indexOf("Organisation Administrator") > -1 ||
      this.user.roles.indexOf("Billing") > -1 )
       {
      this.isAdmin = true;
      
    }
     console.log(this.isAdmin);
     if(this.isAdmin)
     {
      eventService
        .getByDates(this.currentOrganisation.id, startOfTheMonth, endOfTheMonth)
        .then(resultx => {
          resultx.subscribe(result => {
            me.events.splice(0, me.events.length);
            result.map(data => {
              if (!data.isReccuring) {
                data.start = data.start.toDate();
                data.end = data.end.toDate();
              } else {
                if (data.startRecurDate != undefined) {
                  data.startRecur = data.startRecurDate.toDate();
                }
                if (data.endRecurDate != undefined) {
                  data.endRecur = data.endRecurDate.toDate();
                }
              }
              console.log(data);
              me.events.push(data);
              me.applyFilters(me);
            });

            me.loading = false;
            return me.items;
          });
        })
        .catch(error => {
          me.loading = false;
          console.log(error);
        })
        .finally(() => {
          me.loading = false;
        });
     } else{
        console.log(this.user.id);
      eventService
        .getByDatesAndUserId(this.currentOrganisation.id, startOfTheMonth, endOfTheMonth, this.user.id)
        .then(resultx => {
          resultx.subscribe(result => {
            me.events.splice(0, me.events.length);
            result.map(data => {
              if (!data.isReccuring) {
                data.start = data.start.toDate();
                data.end = data.end.toDate();
              } else {
                if (data.startRecurDate != undefined) {
                  data.startRecur = data.startRecurDate.toDate();
                }
                if (data.endRecurDate != undefined) {
                  data.endRecur = data.endRecurDate.toDate();
                }
              }
              console.log(data);
              me.events.push(data);
              me.applyFilters(me);
            });

            me.loading = false;
            return me.items;
          });
        })
        .catch(error => {
          me.loading = false;
          console.log(error);
        })
        .finally(() => {
          me.loading = false;
        });
     }
    },
    addEvent(obj) {
      let newEvent = null;
      if (obj.date != undefined) {
        newEvent = { guests: [] };
        const dt = moment(obj.date);
        newEvent.startDateString = dt.format("YYYY-MM-DD");
        newEvent.startRecurString = dt.format("YYYY-MM-DD");

        newEvent.startTimeString = obj.allDay ? "12:30" : dt.format("hh:mm");
        const dtEnd = dt.add(1, "hours");
        newEvent.endDateString = dtEnd.format("YYYY-MM-DD");
        newEvent.endRecurString = dtEnd.format("YYYY-MM-DD");
        newEvent.endTimeString = obj.allDay ? "13:30" : dtEnd.format("hh:mm");
        newEvent.backgroundColor = "#3398db";
        newEvent.textColor = "#FFFFFF";
      }
      this.$store.commit("setCurrentEvent", newEvent);
      this.$router.push("/roster/event");
    },
    resizeEvent(obj) {
      const event = obj.event;
      const matchedEvent = this.events.find(e => {
        return e.id == event.id;
      });

      matchedEvent.start = event.start;
      matchedEvent.end = event.end;
      matchedEvent.startDate = event.start;
      if (event.end != null) {
        matchedEvent.endDate = event.end;
      } else {
        matchedEvent.endDate = event.start;
      }
      if (matchedEvent.isReccuring) {
        matchedEvent.startTime = moment(event.start).format("HH:mm");
        matchedEvent.endTime = moment(event.end).format("HH:mm");
        matchedEvent.startTimeString = matchedEvent.startTime;
        matchedEvent.endTimeString = matchedEvent.endTime;
      } else {
        matchedEvent.startDateString = moment(matchedEvent.startDate).format(
          "YYYY-MM-DD"
        );
        matchedEvent.startTimeString = moment(matchedEvent.startDate).format(
          "HH:mm"
        );

        matchedEvent.endDateString = moment(matchedEvent.endDate).format(
          "YYYY-MM-DD"
        );
        matchedEvent.endTimeString = moment(matchedEvent.endDate).format(
          "HH:mm"
        );
      }
      //this.$refs.calendar.$emit("rerender-events");
      this.saveEvent(matchedEvent);
    },
    saveEvent(event) {
      let me = this;
      const obj = Object.assign({}, event);

      eventService
        .save(me.currentOrganisation.id, obj)
        .then(ref => {
          if (ref != undefined) {
            event.id = ref.id;
          }
          me.$store.dispatch("setToast", {
            message: "Event saved",
            color: "success"
          });
          //this.$router.push("/roster");
        })
        .catch(error => {
          console.error("Error saving event: ", error);
        });
    },

    eventSelected(obj) {
      const matchedEvent = this.events.find(e => {
        return e.id == obj.event.id;
      });
      console.log(matchedEvent);
      this.$store.commit("setCurrentEvent", matchedEvent);
      this.$router.push("/roster/event");
    },
    
  }
};
</script>

<style>
</style>