                <template>
  <v-combobox
    v-model="guestSearch"
    :items="items"
    :loading="loading"
    :value="guestValue"
    :search-input.sync="search"
    :item-text="setText"
    item-value="guest"
    label="Add guests"
    placeholder="Start typing to search or enter in an email address "
    prepend-icon="mdi-account-search"
    return-object
    @input="addGuest"
    auto-select-first
    clearable
  >
    <template v-slot:item="{ index, item }">
      <v-list-item-avatar>
        <v-badge class="align-self-center" bottom overlap color="transparent">
          <template v-slot:badge>
            <v-icon color="blue" v-if="item.type=='Participant'">mdi-account</v-icon>
            <v-icon color="green " v-if="item.type=='Staff'">mdi-account-tie</v-icon>
            <v-icon v-if="item.type=='Other'">mdi-account-question</v-icon>
          </template>
          <img :src="item.avatar" v-if="item.avatar" style="height:40px" />
          <v-icon v-if="item.avatar==undefined ||item.avatar==null">mdi-account</v-icon>
        </v-badge>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{item.name}} ({{item.type}})</v-list-item-title>

        <v-list-item-subtitle v-if="item.name">{{item.email}}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-combobox>
</template>


<script>
import * as eventService from "../../services/eventService";

export default {
  name: "addressPicker",
  data: () => ({
    guestSearch: null,
    loading: false,
    guestValue: "",
    minChars: 3,
    search: null,
    items: []
  }),
  props: {
    minCharacters: [Number, String],
    value: { required: false }
  },
  mounted() {
    this.initialising = true;
    this.init();
    this.initialising = false;
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    }
  },
  methods: {
    init() {
      if (
        this.minCharacters !== undefined &&
        this.minCharacters != null &&
        new String(this.minCharacters).length > 0
      ) {
        this.minChars = parseInt(new String(this.minCharacters));
      }
    },
    setText(item) {
      console.log(item);
      return item.name + "(" + item.type + ")";
    },

    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      const me = this;
      me.items.splice(0, me.items.length);
      eventService
        .guestSearch(this.currentOrganisation.id, v)
        .then(result => {
          result.subscribe(result2 => {
            result2.map(data => {
              if (data.avatar == undefined || data.avatar == null) {
                data.avatar = "";
              }
              me.items.push(data);
            });

            me.loading = false;
            return me.items;
          });
        })
        .catch(error => {
          me.loading = false;
          console.log(error);
        })
        .finally(() => {
          me.loading = false;
        });
    },
    addGuest(guest) {
      if (guest == undefined) {
        return;
      }

      let newGuest = guest;

      if (typeof guest === "string") {
        if (!this.validateEmail(guest)) {
          return;
        }
        newGuest = { email: guest, type: "Other", id: this.uuidv4() };
      }

      this.$emit("guest-selected", newGuest);

      this.guestValue = "";
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }

      return false;
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

