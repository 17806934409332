import Vue from "vue";
import moment from "moment";


function getRef() {
  return Vue.prototype.$db.collection("lineItemDefinitions");
}

export async function getById(id) {
  return getRef()
    .doc(id)
    .get();
}

export async function list() {
  return getRef().get();
}

export async function listBySupportCategoryAndRegistrationGroup(
  id,
  supportCategoryNumber,
  registrationGroupNumber
) {
  return getRef()
    .doc(id)
    .collection("lineItems")
    .where("supportCategoryNumber", "==", supportCategoryNumber)
    .where("registrationGroupNumber", "==", registrationGroupNumber)
    .get();
}

export async function save(payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef()
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef().add(obj);
  }
}

export async function listCurrent(){
  const now = moment(new Date()).format("YYYY-MM-DD");
  console.log(now);
  return getRef()
    .where("effectiveToDate", ">=",now )
    .get();
}

export async function listAllLineItems(lineItemDefinitionId){
  return getRef()
  .doc(lineItemDefinitionId)
  .collection("lineItems")
  .get();

}

