import Vue from "vue";

function getRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("refData");
}

export async function getById(orgId, id) {
  return getRef(orgId)
    .doc(id)
    .get();
}
export async function listByKey(orgId, key) {
  return getRef(orgId)
    .where("key", "==", key)
    .get();
}
export async function list(orgId) {
  return getRef(orgId).get();
}

export async function save(orgId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId).add(obj);
  }
}

export async function remove(orgId, id) {
  return getRef(orgId)
    .doc(id)
    .delete();
}
